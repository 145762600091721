import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function OrderDetails(props) {
  console.log(props.id);
  const [orderData, setOrderData] = useState([])

  useEffect(() => {
    if( props.id != ""){
    axios
      .get(`https://allinonetables.com/api/order/${props.id}`)
      .then((res) => {
        const response = res.data[0];

        console.log(15, response);
        setOrderData(response);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }, [props]);


  function formatPrice(price) {
    // Check if price is a valid number
    if (typeof price !== "number" || isNaN(price)) {
      // Handle invalid or non-numeric input
      return "Invalid Price";
    }

    // Use toLocaleString to format the number with commas and adhere to locale settings
    const formattedPrice = price.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedPrice;
  }
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-10">
            <div className="quote-summery-box-name-details">
              {/* <span className="quote-summery-number ps-2">{myStoredValue}</span> */}
            </div>
            <div className="quote-summery-heading p-0 d-flex">
              <h2>
                <strong>Order Details</strong>
              </h2>
            </div>

          </div>
          <div className="col-lg-2">
            {/* <Link to="/profile/2">
            <button className="ViewOrder-buttton text-white ">
              back
            </button>
          </Link> */}

          </div>
        </div>
      </div>
      <div className="checkoutDeliveryOption border">
        <div className="row g-3">
          <div className="contactinfo1">
            <div className="deliverymain">
              <div className="border-bottom">
                {/* <h5 className="ship">Order</h5> */}
                <p className="depart mb-3"> <strong>Order ID: </strong>{orderData?.orderId}</p>
              </div>
              <div className="deliverymain1">
               

                <div className="col-9 col-sm-9 col-md-10 col-lg-11 col-xl-11">
                  {/* <span>
                    Biyork Vinyl Planks HydroGen 5 Gold Coast Click Lock
                  </span> */}
                  <p className="m-0">
                    <strong>Name: </strong>
                    {`${orderData?.firstName}  ${orderData?.lastName}`}
                  </p>
                  <p className="m-0">
                    <strong>Email: </strong>
                    {`${orderData?.email}`}
                  </p>
                  <p className="m-0">
                    <strong>Mobile Number: </strong>
                    {`${orderData?.mNo}`}
                  </p>
                  {/* <p className="m-0">
                    <strong>Box Quantity: </strong>1
                  </p>
                  <p className="m-0">
                    <strong>Box Coverage: </strong>
                    23.11 sq. ft. / box
                  </p>
                  <p className="m-0">
                    <strong>Box Price: </strong>
                    CA$45.00
                  </p> */}
                  <p className="m-0">
                    <strong>Total: </strong>
                    CAD ${formatPrice(orderData?.totalPrice)} 
                  </p>
                  <p className="">
                    <strong>Payment Status: </strong>
                    {orderData?.paymentStatus ? "Done" : "Not Done"}
                  </p>
                </div>
              </div>

              <h5 className="delivry m-0 ">Products:</h5>

              {
                orderData?.orderItems?.map((item) => {
                  return (<>
                    <hr />
                    <div className="row mt-4 ">
                      <div className="col-lg-12">


                        <p className="m-0">
                          <strong>Product Name: </strong>
                          {item?.ProductName}
                        </p>
                      </div>
                      <div className="col-lg-6">


                        <p className="m-0">
                          <strong>Box Covrage: </strong>
                          {item?.addTenPer ? item?.boxCovrageWithTenPer : item?.boxCovrage}
                        </p>
                      </div>
                      <div className="col-lg-6">


                        <p className="m-0">
                          <strong>Box Quantity: </strong>
                          {item?.boxQuantity}
                        </p>
                      </div>
                      <div className="col-lg-6">


                        <p className="m-0">
                          <strong> Per Box Price: </strong>
                          CAD $ { formatPrice(item?.perBoxPrice)}
                        </p>
                      </div>
                      <div className="col-lg-6">


                        <p className="m-0">
                          <strong>Per Box Covrage: </strong>
                          {formatPrice(item?.perboxCovrage)}
                        </p>
                      </div>

                      <div className="row deliverymain2 pt-3">

                        {/* ********************** Warehouse *********** */}
                        {
                          item.is_werehousePickup ? (<>
                            <div className=" col-md-6 col-lg-6 col-xl-6">
                              {" "}
                              <div
                                className="paracel"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                              >
                                <div className="d-flex justify-content-between align-items-center">
                                  <h6 className="m-0">Warehouse Pickup</h6>
                                </div>

                                <p className="m-0">
                                  <strong>Werehouse Address: </strong>
                                  {item?.WerehouseAddress}
                                </p>
                                <p className="m-0">
                                  <strong>Pickup Reciver First Name: </strong>
                                  {item?.pickup_firstName}
                                </p>
                                <p className="m-0">
                                  <strong>Pickup Reciver Last Name: </strong>
                                  {item?.pickup_lastName}
                                </p>
                                <p className="m-0">
                                  <strong>Pickup Reciver Email: </strong>
                                  {item?.pickup_email}
                                </p>
                                <p className="m-0">
                                  <strong>Pickup Date: </strong>
                                  {item?.pickup_Date}
                                </p>
                              </div>
                            </div>

                          </>) : (<>

                            <div className=" col-md-6 col-lg-6 col-xl-6">
                              {" "}
                              <div className="paracel">
                                <div className="d-flex justify-content-between align-items-center">
                                  <h6>Scheduled Delivery</h6>
                                </div>

                                <p className="m-0">
                                  8187 Strosin Extensions, East Ottochester, VA 11509 <br />
                                  delivery address
                                </p>
                              </div>
                            </div>
                          </>)
                        }
                        {/* ********Scheduled Delivery************* */}


                      </div>

                    </div>

                  </>)
                })
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
