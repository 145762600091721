import axios from "axios";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import url from "../Url";
import "./Navbar.css";
import months from "../assets/images/collection/monthly.jpg";
import clear from "../assets/images/collection/clearance.jpg";
import discount from "../assets/images/collection/discounts.jpg";
import faqs from "../assets/images/icons/faq.png";
import shipping from "../assets/images/icons/delivery-truck.png";
import contact from "../assets/images/icons/telephone.png";
import about from "../assets/images/icons/shop.png";

function Header() {
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);
  const [menuVisibility, setMenuVisibility] = useState([]);
  const [isInteractable, setIsInteractable] = useState(true); 
  const [submenuState, setSubmenuState] = useState({
    isVisible: false,
    canToggle: true,
  });
  const navigate = useNavigate();

  const handleMenuClick = (index) => {

    setMenuVisibility(prev => {
      const newState = {...prev}; // Spread into a new object
      Object.keys(newState).forEach(key => {
        newState[key] = false; // Close all menus
      });
      newState[index] = !prev[index]; // Toggle clicked menu
      return newState;
    });
  };
  let timeoutIds = {};
  let showTimeout;

  // Defined outside of your component
  useEffect(() => {
    return () => {
      Object.values(timeoutIds).forEach(clearTimeout);
    };
  }, []);

  const menuRef = useRef(); // Create a ref for the menu

  useOutsideClick(menuRef, () => {
    setMenuVisibility({}); // Assuming this closes all menus, adjust as needed
  });
   // Hook to handle clicking on categories to close menuuseCatClick

  function useOutsideClick(ref, callback) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, callback]);
  }
  
  const [mainCat, setMaincat] = useState([]);

  useEffect(() => {
    axios
      .get(`${url}/api/category/getCat`)
      .then((response) => {
        console.log(100, response.data.categoryList);
        console.log(7, mainCat);
        setMaincat(response.data.categoryList);
      })
      .catch((err) => {
        console.log(17, err);
      });
  }, []);

  const catNavigate = (id) => {

    console.log(17, "faq clicked try refresh");
    navigate(`/category/${id}`);
    
  };
  const faqNavigate = () => {
    console.log(17, "faq clicked try refresh");
    navigate(`/faq`);
  };
  const shipNavigate = () => {

    navigate(`/ship`);
  };
  const contactNavigate = () => {

    navigate(`/contactUs`);
  };
  const aboutNavigate = () => {

    navigate(`/about`);
  };
  const brandNavigate = () => {
  
    navigate(`/brand`);
    window.location.reload();
  };

  const monthNavigate = () => {
 
    navigate(`/MonthSpecialPage`);
  };
  const clearNavigate = () => {

    navigate(`/clearencePage`);
  };
  const discountNavigate = () => {

    navigate(`/discoutPage`);
  };
  //   ************************************************ month name
  // Creating a date object
  var today = new Date();

  // Getting full month name (e.g. "June")
  var month = today.toLocaleString("default", { month: "long" });
  return (
    <>
      {/********************** Long screen navbar  ************************/}
      <header className="header">
        <div className="container">
          <div className="header-item item-center">
            <div className="menu-overlay"></div>

            <nav className="menu" ref={menuRef}>
              <ul className="menu-main">
                {mainCat.map((superCat, index) => (
                  <li key={superCat._id} className="menu-item-has-children"
                      >
                    <Link
                      onClick={(e) => handleMenuClick(index)}
                      
                    >
                      {superCat.name}
                    </Link>
                    <div
                     className={`sub-menu mega-menu mega-menu-column-4 ${menuVisibility[index] ? "visible" : "hidden"} `}>
                      {superCat.children.map((cat) => (
                        <div key={cat._id} className="list-item text-center">
                          <Link to={`/category/${cat._id}`}
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the default link behavior
                            catNavigate(cat._id); // Perform the navigation
                            setMenuVisibility({}); // Close all menus
                          }}
                          >
                            <div className="sub-menu-gradient">
                              <div className="gradient-overlay"></div>
                              <img
                                src={cat.categoryImage}
                                alt="Category"
                              />
                              <h4 className="title text-start">{cat.name}</h4>
                            </div>
                          </Link>

                          <ul className="subset">
                            {cat.children.map((subCat) => (
                              <li key={subCat._id}>
                    
                                <Link to={`/category/${subCat._id}`}
                                onClick={(e) => {
                                  e.preventDefault(); // Prevent the default link behavior
                                  catNavigate(subCat._id); // Perform the navigation
                                  setMenuVisibility({}); // Close all menus
                                }}
                                >  
                                  {subCat.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </li>
                ))}

                <li className="menu-item-has-children"
                >
                  <Link onClick={() => handleMenuClick(mainCat.length)} >Promotions</Link>
                  <div className={`sub-menu mega-menu mega-menu-column-4 ${menuVisibility[3] ? "visible" : "hidden"}`}>
                    <div className="list-item text-center">
                      <Link 
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default link behavior
                        clearNavigate(); // Navigation function for the clearance page
                        setMenuVisibility({}); // Close all menus
                      }}
                      >
                        <div className="sub-menu-gradient">
                          <div className="gradient-overlay"></div>
                          <img src={clear} alt="new Product" />

                          <h4 className="title text-start">
                            Clearance <br /> Sale{" "}
                            <i className="bi bi-exclamation-circle-fill"></i>
                          </h4>
                        </div>
                      </Link>
                    </div>
                    <div className="list-item text-center">
                      <Link 
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default link behavior
                        discountNavigate(); // Navigation function for the clearance page
                        setMenuVisibility({}); // Close all menus
                      }}
                      >
                        <div className="sub-menu-gradient">
                          <div className="gradient-overlay"></div>
                          <img src={discount} alt="new Product" />
                          <h4 className="title text-start">
                            Discount <br /> Sale{" "}
                            <i className="bi bi-exclamation-circle-fill"></i>
                          </h4>
                        </div>
                      </Link>
                    </div>
                    <div className="list-item text-center">
                      <Link 
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default link behavior
                        monthNavigate(); // Navigation function for the clearance page
                        setMenuVisibility({}); // Close all menus
                      }}
                      >
                        <div className="sub-menu-gradient">
                          <div className="gradient-overlay"></div>
                          <img src={months} alt="new Product" />
                          <h4 className="title text-start">
                            {month} <br />
                            Special{" "}
                            <i className="bi bi-exclamation-circle-fill"></i>
                          </h4>
                        </div>
                      </Link>
                    </div>
                  </div>
                </li>

                <li className="menu-item-has-children"
                >
                  <Link onClick={() => handleMenuClick(mainCat.length + 1)}>Support</Link>
                  <div className={`sub-menu mega-menu mega-menu-column-4 ${menuVisibility[4] ? "visible" : "hidden"}`}>
                    <div className="list-item support-circle text-center">
                      <Link 
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default link behavior
                        faqNavigate(); // Navigation function for the clearance page
                        setMenuVisibility({}); // Close all menus
                      }}
                      >
                        <img className="small" src={faqs} alt="new Product" />
                        <h3 className="title text-center">FAQ</h3>
                      </Link>
                    </div>
                    <div className="list-item support-circle text-center">
                    <Link 
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default link behavior
                        shipNavigate(); // Navigation function for the clearance page
                        setMenuVisibility({}); // Close all menus
                      }}
                      >
                        <img
                          className="small"
                          src={shipping}
                          alt="new Product"
                        />
                        <h3 className="title text-center">Shipping Info</h3>
                      </Link>
                    </div>
                    <div className="list-item support-circle text-center">
                      <Link 
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default link behavior
                        contactNavigate(); // Navigation function for the clearance page
                        setMenuVisibility({}); // Close all menus
                      }}
                      >
                      
                        <img
                          className="small"
                          src={contact}
                          alt="new Product"
                        />
                        <h3 className="title text-center">Contact Us</h3>
                      </Link>
                    </div>
                    <div className="list-item support-circle text-center">
                      <Link 
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default link behavior
                        aboutNavigate(); // Navigation function for the clearance page
                        setMenuVisibility({}); // Close all menus
                      }}
                      >
                        <img className="small" src={about} alt="new Product" />
                        <h3 className="title text-center">About US</h3>
                      </Link>
                    </div>
                  </div>
                </li>
                <li>
                  <Link 
                  onClick={(e) => {
                    e.preventDefault(); // Prevent the default link behavior
                    brandNavigate(); // Navigation function for the clearance page
                    setMenuVisibility({}); // Close all menus
                  }}
                  >Brands</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
