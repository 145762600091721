import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./components/Header";
import "./components/Navbar.css";
import _ from "lodash";
import axios from "axios";
import "./productsearch.css";
import { useDispatch, useSelector } from "react-redux";
import FreeSample from "./components/freeSample/FreeSample";
import url from "./Url";
import { getCart } from "./store/slices/CardSlice";
import { FaBars } from "react-icons/fa";

function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

function Navbar() {
  const triggerRef = useRef(null);
  const [value, setValue] = useState("");
  const [data, setData] = useState("");
  const navigate = useNavigate();
  const FevProduct = useSelector((state) => state.favroite);
  const cart = useSelector((state) => state.cart.data);
  const [cartNoUser, setCartNoUser] = useLocalStorage("cart", []); // Local storage cart for non-signed-in users
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [menuVisibility, setMenuVisibility] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const searchContainerRef = useRef(null);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCart());
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/api/product/getAll`, {
          params: {
            // page: 0,
            // limit: 10,
          },
        });
        console.log(response, "res");
        setData(response?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);
  
  console.log(cart, "cartUmar");
  const cartItems = userData ? cart : cartNoUser;
  // Throttle the handleChange function to be called at most once every 500 milliseconds
  const throttledHandleChange = _.throttle((inputValue) => {
    // Perform your logic here, like updating state or making API calls
    axios
      .get(
        `https://allinonetables.com/api/product/getBysearch?search=${inputValue}`
      )
      .then((res) => {
        if (inputValue === " ") {
          setData([]);
        } else {
          console.log(res?.data?.products, "searchdata");
          setData(res?.data?.products);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, 500);
  const handleSearchIconClick = () => {
    setShowSearchResults(!showSearchResults);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target)
      ) {
        setShowSearchResults(false);
      }
    };
    const handleScroll = () => {
      setShowSearchResults(false);
    };
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [searchContainerRef, triggerRef]);

  const handleChange = (event) => {
    setIsInputFocused(true);
    const inputValue = event.target.value;
    setValue(inputValue);
    // Call the throttled function
    if (inputValue === "") {
      setData([]);
    } else {
      throttledHandleChange(inputValue);
    }
  };
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };
  const handleRowClick = (slug) => {
    navigate(`productdetail/${slug}`);
    setIsInputFocused(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (triggerRef.current && !triggerRef.current.contains(event.target)) {
        setIsInputFocused(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [triggerRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (triggerRef.current && !triggerRef.current.contains(event.target)) {
        setIsInputFocused(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.querySelector("body").addEventListener("click", handleInputFocus);

    return () => {
      document
        .querySelector("body")
        .removeEventListener("click", handleInputBlur);
    };
  }, []);
  // *************************************************
  useEffect(() => {
    // Enable Bootstrap tooltips
    const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltips.forEach((tooltip) => {
      new window.bootstrap.Tooltip(tooltip);
    });
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      console.log("Received event:", event);
      if (event.detail === "cart") {
        const newCart = JSON.parse(localStorage.getItem("cart") || "[]");
        setCartNoUser(newCart);
        console.log("Cart updated from custom event:", newCart);
      }
    };

    window.addEventListener("onLocalStorageChange", handleStorageChange);
    return () =>
      window.removeEventListener("onLocalStorageChange", handleStorageChange);
  }, []);

  const handleMenuClick = (index) => {
    setMenuVisibility((prev) => {
      const newState = { ...prev }; // Spread into a new object
      Object.keys(newState).forEach((key) => {
        newState[key] = false; // Close all menus
      });
      newState[index] = !prev[index]; // Toggle clicked menu
      return newState;
    });
  };

  const [mainCat, setMaincat] = useState([]);

  useEffect(() => {
    axios
      .get(`${url}/api/category/getCat`)
      .then((response) => {
        console.log(100, response.data.categoryList);
        console.log(7, mainCat);
        setMaincat(response.data.categoryList);
      })
      .catch((err) => {
        console.log(17, err);
      });
  }, []);

  const catNavigate = (id) => {
    console.log(17, "faq clicked try refresh");
    navigate(`/category/${id}`);
  };
  const homeNavigate = () => {
    setMenuVisibility({}); // This line hides all menus
    navigate(`/`);
  };
  const faqNavigate = () => {
    console.log(17, "faq clicked try refresh");
    navigate(`/faq`);
  };
  const shipNavigate = () => {
    navigate(`/ship`);
  };
  const contactNavigate = () => {
    navigate(`/contactUs`);
  };
  const aboutNavigate = () => {
    navigate(`/about`);
  };
  const brandNavigate = () => {
    navigate(`/brand`);
    window.location.reload();
  };
  const cartNavigate = () => {
    navigate(`/cart/${userData?.user?.email}`, { replace: true });
  };
  const accountNavigate = () => {
    userData == undefined || userData === null
      ? navigate(`/login`)
      : navigate(`/profile`);
  };
  const wishlistNavigate = () => {
    userData == undefined || userData === null
      ? navigate(`/login`)
      : navigate(`/profile/4`);
  };
  const monthNavigate = () => {
    navigate(`/MonthSpecialPage`);
  };
  const clearNavigate = () => {
    navigate(`/clearencePage`);
  };
  const discountNavigate = () => {
    navigate(`/discoutPage`);
  };
  // Creating a date object
  var today = new Date();

  // Getting full month name (e.g. "June")
  var month = today.toLocaleString("default", { month: "long" });
  return (
    <div>
      {/* *********Top Header ***************/}
      <div className="top-header">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="top">
                <p>
                  <Link to="/contactUs">
                    <i className="fa-sharp fa-solid fa-location-dot"></i>{" "}
                    Contact Us
                  </Link>
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="phone">
                <p>
                  <a href="tel:905 277 2227">
                    <i className="fa-solid fa-phone"></i> (905) 302 6683
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/**************** Second Header ***************/}

      <div className="content">
        <div className="second-header pt-2 for-mobile-sticky">
          <div className="container">
            <div className="row v-center pb-2 ">
              <div className="col-6 col-sm-6 col-md-1 col-lg-1">
                <div className="header-item item-left">
                  <nav className="navbar  responsive-nav-for-sm-screen mobile-navbar-spacing">
                    <div className="container">
                      <button
                        className="custom-toggler"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasDarkNavbar"
                        aria-controls="offcanvasDarkNavbar"
                        aria-label="Toggle navigation"
                        style={{
                          background: "transparent", // Transparent background
                          border: "none", // No border
                          padding: "0", // Remove default padding
                          outline: "none", // Ensure no outline
                          cursor: "pointer", // Pointer cursor
                        }}
                      >
                        <FaBars size={24} color="currentColor" />{" "}
                        {/* Use the icon */}
                      </button>

                      <div
                        className="offcanvas offcanvas-start bg-color-for-canvas"
                        style={{ width: "85%" }}
                        tabIndex="-1"
                        id="offcanvasDarkNavbar"
                        aria-labelledby="offcanvasDarkNavbarLabel"
                      >
                        <div className="offcanvas-header">
                          <div className="logo">
                            <Link to="/">
                              <img
                                src="https://flooring-deals-bucket.nyc3.cdn.digitaloceanspaces.com/logo-s.jpg"
                                style={{ width: "100px" }}
                                alt=""
                              />
                            </Link>
                          </div>
                          <button
                            type="button"
                            className="btn-close btn-close-dark"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "86%",
                              height: "2px",
                              backgroundColor: "#D3D3D3",
                            }}
                          ></div>
                        </div>
                        <div className="offcanvas-body">
                          <ul className="navbar-nav justify-content-end flex-grow-1 pe-1">
                            <li className="nav-item">
                              <Link
                                className="nav-link active"
                                aria-current="page"
                                to="/"
                                onClick={() => homeNavigate()}
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                Home
                              </Link>
                            </li>

                            {/* {/ **********************   Flooring ********************/}

                            {mainCat.map((superCat, index) => (
                              <li
                                key={superCat._id}
                                className="nav-item dropdown"
                              >
                                <Link
                                  className="nav-link dropdown-toggle"
                                  to={`/category/${superCat._id}`}
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  onClick={() => handleMenuClick(index)}
                                >
                                  {superCat.name}
                                </Link>
                                <ul
                                  className="dropdown-menu bg-color text-start"
                                  style={{ border: "none" }}
                                >
                                  {superCat.children.map((cat) => (
                                    <div key={cat._id}>
                                      <h4>
                                        <Link
                                          className="text-dark ps-2"
                                          to={`/category/${cat._id}`}
                                          onClick={() => catNavigate(cat._id)}
                                          data-bs-dismiss="offcanvas"
                                          aria-label="Close"
                                        >
                                          <span
                                            style={{
                                              fontSize:
                                                window.innerWidth < 768
                                                  ? "15px"
                                                  : "inherit",
                                              fontWeight: "bold",
                                              marginLeft: "-7px",
                                            }}
                                          >
                                            {cat.name}
                                          </span>
                                          {/* {cat.name} */}
                                        </Link>
                                      </h4>
                                      {cat.children.map((subCat) => (
                                        <li
                                          key={subCat._id}
                                          style={{ marginLeft: "-13px" }}
                                        >
                                          <Link
                                            // to={`/category/${subCat._id}`}
                                            className="dropdown-item"
                                            onClick={() =>
                                              catNavigate(subCat._id)
                                            }
                                            data-bs-dismiss="offcanvas"
                                            aria-label="Close"
                                          >
                                            <span
                                              style={{
                                                fontSize:
                                                  window.innerWidth < 768
                                                    ? "14px"
                                                    : "inherit",
                                              }}
                                            >
                                              {subCat.name}
                                            </span>
                                            {/* {subCat.name} */}
                                          </Link>
                                        </li>
                                      ))}
                                      <hr />
                                    </div>
                                  ))}
                                </ul>
                              </li>
                            ))}

                            {/* {/ ******************************  Promotions  **************************/}
                            <li className="nav-item dropdown">
                              <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Promotions
                              </a>
                              <ul
                                className="dropdown-menu bg-color text-start ps-2"
                                style={{ border: "none" }}
                              >
                                <li style={{ marginLeft: "-23px" }}>
                                  <Link
                                    to="/clearencePage"
                                    className="dropdown-item"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                    onClick={() => clearNavigate()}
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Clearance Sale{" "}
                                    <i className="bi bi-exclamation-circle-fill"></i>
                                  </Link>
                                </li>
                                <hr />
                                <li style={{ marginLeft: "-23px" }}>
                                  <Link
                                    to="/discountPage"
                                    className="dropdown-item"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                    onClick={() => discountNavigate()}
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Discount Sale{" "}
                                    <i className="bi bi-exclamation-circle-fill"></i>
                                  </Link>
                                </li>
                                <hr />
                                <li style={{ marginLeft: "-23px" }}>
                                  <Link
                                    to="/MonthSpecialPage"
                                    className="dropdown-item"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                    onClick={() => monthNavigate()}
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {month} Special{" "}
                                    <i className="bi bi-exclamation-circle-fill"></i>
                                  </Link>
                                </li>
                              </ul>
                            </li>

                            {/* {/ *********************************** Support **************************/}
                            <li className="nav-item dropdown">
                              <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Support
                              </a>
                              <ul
                                className="dropdown-menu bg-color text-start ps-2"
                                style={{ border: "none" }}
                              >
                                <li style={{ marginLeft: "-23px" }}>
                                  <Link
                                    to="/faq"
                                    className="dropdown-item"
                                    onClick={() => faqNavigate()}
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    FAQ
                                  </Link>
                                </li>

                                <li style={{ marginLeft: "-23px" }}>
                                  <Link
                                    to="/ship"
                                    className="dropdown-item"
                                    onClick={() => shipNavigate()}
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Shipping Info
                                  </Link>
                                </li>

                                <li style={{ marginLeft: "-23px" }}>
                                  <Link
                                    to="/contactUs"
                                    className="dropdown-item"
                                    onClick={() => contactNavigate()}
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Contact Us
                                  </Link>
                                </li>

                                <li style={{ marginLeft: "-23px" }}>
                                  <Link
                                    to="/about"
                                    className="dropdown-item"
                                    onClick={() => aboutNavigate()}
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    About US
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            {/* {/ ************************* Brand  ********************************/}
                            <li className="nav-item">
                              <Link
                                to="/brand"
                                className="nav-link"
                                onClick={() => brandNavigate()}
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                Brand
                              </Link>
                            </li>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "5%",
                              }}
                            >
                              <div
                                style={{
                                  width: "97%",
                                  height: "2px",
                                  backgroundColor: "#D3D3D3",
                                }}
                              ></div>
                            </div>
                            <li className="nav-item">
                              {/* <Link
                                to={`/cart/${userData?.user?.email}`}
                                className="nav-link"
                                onClick={() => cartNavigate()}
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                Cart
                              </Link> */}
                              <Link
                                to="/profile"
                                className="nav-link"
                                onClick={() => accountNavigate()}
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                My Account
                              </Link>
                            </li>
                            <Link
                              to="/profile/4"
                              className="nav-link"
                              onClick={() => wishlistNavigate()}
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                            >
                              Wish List
                            </Link>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </nav>
                  <div className="logo">
                    <Link to="/">
                      <img
                        src="https://flooring-deals-bucket.nyc3.cdn.digitaloceanspaces.com/logo-s.jpg"
                        style={{ width: "100px" }}
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-5 col-lg-5 hide-on-mobile">
                <div className="header-item item-center py-1 py-md-1 ms-md-5 position-relative">
                  <div className="searching">
                    <i className="fas fa-search"></i>
                    <input
                      type="text"
                      name=""
                      ref={triggerRef}
                      value={value}
                      id=""
                      onChange={(e) => handleChange(e)}
                      placeholder="Search Your Product"
                      onFocus={handleInputFocus}
                      // onBlur={handleInputBlur}
                    />
                  </div>
                  {isInputFocused && (
                    <div className="container ">
                      <div className="search-bar-for-search-option">
                        <div className="row">
                          <>
                            {data?.length > 0 ? (
                              <>
                                {data?.map((item) => {
                                  return (
                                    <>
                                      <div
                                        className="search-grid "
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          //  navigate(`productdetail/${item.item.slug}`);
                                          handleRowClick(item?.slug);
                                          //  setIsInputFocused(false);
                                        }}
                                      >
                                        <div className="imageSearchProduct mt-2">
                                          <img
                                            className="img-fluid rounded-4"
                                            src={item?.productPictures[0]}
                                            alt=""
                                          />
                                        </div>
                                        <div className=" d-flex align-item-center">
                                          <div className="discription-search mt-2">
                                            <p className="m-0 search-head">
                                              {item?.name}
                                            </p>
                                            {/* <p className="m-0 search-number">
                                              BYKRCHY50CA
                                            </p> */}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <p>No Product Match</p>
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* ********************************************** */}

              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <div className="header-item item-right">
                  {/* **************************** */}

                  {/* ************************** */}
                  {userData == undefined || userData === null ? (
                    <>
                      <Link
                        to="/login"
                        // data-bs-toggle="modal"
                        // data-bs-target="#loginSignupForm"
                        className="user"
                      >
                        <i className="bi bi-person"></i>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link className="userwithName" to="/profile">
                        <p className="m-0 ">
                          <i className="bi bi-person-fill-check pe-1"></i>
                          {userData.user.firstName}&nbsp;
                        </p>
                      </Link>
                    </>
                  )}
                  <i
                    className="bi bi-search hide-on-large-screen"
                    onClick={handleSearchIconClick}
                    style={{ cursor: "pointer" }}
                  ></i>
                  {/* ******************** After sign up *************** */}
                  {/* ********************** */}
                  <Link to="/profile/4">
                    <i className="bi bi-suit-heart"></i>
                    {/* <p> Favorites</p> */}
                    {FevProduct?.data?.length > 0 ? (
                      <>
                        <span>{FevProduct?.data?.length || 0}</span>
                      </>
                    ) : (
                      ""
                    )}
                  </Link>

                  {/* **************************** */}
                  {/* <Link to={`/cart/${userData?.user?.email}`}>
                    <i className="bi bi-cart3"></i>
                    {cartItems?.length > 0 ? (
                      <>
                        <span>{cartItems?.length || 0}</span> <br />
                      </>
                    ) : (
                      ""
                    )}
                  </Link> */}
                  <Link
                    className="hide-on-mobile"
                    to="/costestimationInstallationPage"
                  >
                    <i
                      className="bi bi-calculator"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Installation Calculator"
                    ></i>
                  </Link>
                  <Link
                    className="hide-on-mobile"
                    to=""
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                  >
                    <i
                      className="bi bi-columns"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Free Samples"
                    ></i>
                  </Link>
                  <FreeSample />
                </div>
              </div>
              {showSearchResults && (
                <div
                  className="col-sm-12 col-md-5 col-lg-5 hide-on-large-screen"
                  ref={searchContainerRef}
                >
                  <div className="header-item item-center py-1 py-md-1 ms-md-5 position-relative">
                    <div className="searching">
                      <i className="fas fa-search"></i>
                      <input
                        type="text"
                        name=""
                        ref={triggerRef}
                        value={value}
                        id=""
                        onChange={(e) => handleChange(e)}
                        placeholder="Search Your Product"
                        onFocus={handleInputFocus}
                        // onBlur={handleInputBlur}
                      />
                    </div>
                    {isInputFocused && (
                      <div className="container ms-md-5">
                        <div className="search-bar-for-search-option">
                          <div className="row">
                            <>
                              {data?.length > 0 ? (
                                <>
                                  {data?.map((item) => {
                                    return (
                                      <>
                                        <div
                                          className="search-grid "
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            //  navigate(`productdetail/${item.item.slug}`);
                                            handleRowClick(item?.slug);
                                            //  setIsInputFocused(false);
                                          }}
                                        >
                                          <div className="imageSearchProduct mt-2">
                                            <img
                                              className="img-fluid rounded-4"
                                              src={item?.productPictures[0]}
                                              alt=""
                                            />
                                          </div>
                                          <div className=" d-flex align-item-center">
                                            <div className="discription-search mt-2">
                                              <p className="m-0 search-head">
                                                {item?.name}
                                              </p>
                                              {/* <p className="m-0 search-number">
                                              BYKRCHY50CA
                                            </p> */}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                <p>No Product Match</p>
                              )}
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* **************************** Mune Nav  **************************/}
      <Header />
    </div>
  );
}
export default Navbar;
