import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getCart } from "../store/slices/CardSlice";
import "./quotesummery.css";
import NoQuoteSummary from "./flooring/NoQuoteSummary";

function Cart() {
  const { id } = useParams();
  const [originalTotal, setOriginalTotal] = useState(0);
  const [x, setX] = useState(false);
  const [ischeckTerm, setischeckTerm] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const favProduct = useSelector((state) => state.cart.data);
  const [isChecked, setIsChecked] = useState(true);
  const [AddTenPer, setAddTenPer] = useState(0);
  let totalRoomSize = 0;
  const [showForm, setShowForm] = useState(false);
  const [isCheckedTerm, setIsCheckedTerm] = useState(false);
  const myStoredValue = localStorage.getItem("uniqueId");
  const [cart, setCart] = useState([]);
  const [apiCart, setApiCart] = useState([]);
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);
  console.log(26, cart);
  const [nRoom, setNroom] = useState({
    roomType: "",
    roomWidth: 0,
    roomLength: 0,
    totalsqft: 0,
  });
  const [orderData, setOrderData] = useState({
    identifier: myStoredValue,
    orderItems: "",
    firstName: "",
    lastName: "",
    city: "",
    state: "",
    postalCode: 0,
    mNo: 0,
    email: "",
    totalPrice: "",
  });

  // ************************************************ Validation of form *********************
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  // const [inputQuantity, setInputQuantity] = useState(item.boxQuantity);
  const [inputQuantity, setInputQuantity] = useState(1);
  

  const handleInputChange = (fieldName, value) => {
    setOrderData({
      ...orderData,
      [fieldName]: value,
    });
    validateForm(); // Validate the form on each input change
  };

  const handleCheckboxChange1 = () => {
    setTermsAgreed(!termsAgreed);
    validateForm(); // Validate the form when the checkbox is changed
  };

  const validateForm = () => {
    // Check if all required fields are filled and the checkbox is checked
    const isValid =
      orderData.email &&
      orderData.firstName &&
      orderData.mNo &&
      orderData.city &&
      orderData.postalCode &&
      termsAgreed;

    setIsFormValid(isValid);
  };

  const handleFormSubmit = () => {
    PlaceOrder();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isFormValid) {
      // Proceed with the form submission or other actions
      // ...
      console.log("Form submitted successfully");
    } else {
      // Show an error message or take appropriate action
      console.error(
        "Form is not valid. Please fill in all required fields and agree to the terms."
      );
    }
  };
  const [orderItems, setOrderItems] = useState({});
  const tax = 13;
  const [originalPrice, setOriginalPrice] = useState(0);
  const [Sub_Total, setSub_Total] = useState(0);
  const [taxval, setTaxval] = useState(0);
  const [i, seti] = useState(false);
  const [searchId, setsearchId] = useState();
  const [showSearch, setShowSearch] = useState(false);
  useEffect(() => {
    // dispatch(getCart());
    console.log(1277, id);
    if (id) {
      if (id !== "undefined") {
        axios
        .get(`https://allinonetables.com/api/cart/${id}`)
        .then((res) => {
          const resData = res.data;
          console.log(129, resData);
          const adjustedResData = res.data.map(item => ({
            ...item,
            boxCovrage: item.perboxCovrage * Math.ceil(item.boxQuantity),
            boxQuantity: Math.ceil(item.boxQuantity),  // Ensure boxQuantity is rounded up
            total: Math.ceil(item.boxQuantity) * item.perBoxPrice
          }));
          setApiCart(adjustedResData)
          console.log(1299, adjustedResData);
          const storedCart = localStorage.getItem("cart");
          let localCart = storedCart ? JSON.parse(storedCart) : [];
          const combinedCart = [...adjustedResData, ...localCart];
          console.log(combinedCart,"combineNewCart.....................")
          setCart(combinedCart);
          calculateTotalPrice(combinedCart); 
        })
        .catch((err) => console.log(err));

      } else {
        const storedCart = localStorage.getItem("cart");
        const localCart = storedCart ? JSON.parse(storedCart) : [];
        console.log(1299, localCart);
        setCart(localCart);
        calculateTotalPrice(localCart); 
        
      }

      
    } else {
      console.log(186, userData);
      if (userData == null || userData === undefined || userData == "") {
        navigate("/login");
      } else {
        axios
          .get(`https://allinonetables.com/api/cart/${userData.user.email}`)
          .then((res) => {
            const resData = res.data;
            console.log(129, resData);
            setCart(resData);
          })
          .catch((err) => console.log(err));
      }
    }
  }, [i]);

  const deleteCart = (id) => {
    console.log(12999, id);
    const item = localStorage.getItem("user");
  const userData = item ? JSON.parse(item) : null;
  if (id.includes("anonymous")) {
    
    const storedCart = localStorage.getItem("cart");
    const localCart = storedCart ? JSON.parse(storedCart) : [];
    // Log the cart items before deletion
    console.log("Cart before deletion:", localCart.map(item => item._id));

    const updatedCart = localCart.filter((cartItem) => {
      console.log("Checking item id:", cartItem._id);  // Log each item's id during the filter operation
      return cartItem._id !== id;
    });

    // Log the cart after attempting to delete
    console.log("Cart after deletion:", updatedCart.map(item => item._id));
    
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    axios
    .get(`https://allinonetables.com/api/cart/${userData?.user?.email}`)
    .then((res) => {
      const resData = res.data;
      console.log(129, resData);
      const adjustedResData = res.data.map(item => ({
        ...item,
        boxCovrage: item.perboxCovrage * Math.ceil(item.boxQuantity),
        boxQuantity: Math.ceil(item.boxQuantity),  // Ensure boxQuantity is rounded up
        total: Math.ceil(item.boxQuantity) * item.perBoxPrice
      }));
      console.log(1299, adjustedResData);
      const combinedCart = [...adjustedResData, ...updatedCart];
      console.log(combinedCart,"combineNewCart.....................")
      setCart(combinedCart);
      calculateTotalPrice(combinedCart); 
      dispatch(getCart())
    })
    .catch((err) => console.log(err));

    // setCart(updatedCart);
    // toast.success("Product Deleted....",{   autoClose: 1500});
    // calculateTotalPrice(updatedCart); 
    // Dispatch event to indicate change
    window.dispatchEvent(new CustomEvent('onLocalStorageChange', { detail: 'cart' }));
  } else {
    axios
      .delete(`https://allinonetables.com/api/cart/${id}`)
      .then((res) => {
        toast.success("Product Deleted....",{   autoClose: 1500});
        seti(!i);
        dispatch(getCart());
      })
      .catch((err) => console.log(err));
  }
    
  };

  const emptyCart = () => {
    const item = localStorage.getItem("user");
  const userData = item ? JSON.parse(item) : null;
  if (!userData) {
    localStorage.removeItem("cart");
    setCart([]);
    toast.info("Cart has been cleared.");
  } else {
    cart.forEach((item) => {
      deleteCart(item._id);
    });
    setCart([]);
    toast.info("Clearing cart...");
  }
};

const emptyCartWithConfirmation = () => {
  if (window.confirm("Are you sure you want to empty the cart?")) {
      emptyCart();  // Call the existing emptyCart function if confirmed
  }
};




  const PlaceOrder = () => {
    axios
      .post("https://allinonetables.com/api/order/create", orderData)
      .then((e) => {
        toast.success("Order Placed",{   autoClose: 1500});
        navigate("/QueatNumber");
      })
      .catch((err) => console.log(err));
  };

  const updateOrder = (id) => {
    axios
      .put(`https://allinonetables.com/api/order/${searchId}`, orderData)
      .then((e) => {
        toast.success("Order Placed",{   autoClose: 1500});
        navigate("/QueatNumber");
      })
      .catch((err) => console.log(err));
  };
  const addRoom = (index) => {
    if (cart && cart[index]) {
      const singleCart = cart[index];

      // Validate nRoom
      if (
        !nRoom ||
        isNaN(parseFloat(nRoom.roomWidth)) ||
        isNaN(parseFloat(nRoom.roomLength)) ||
        parseFloat(nRoom.roomWidth) < 0 ||
        parseFloat(nRoom.roomLength) < 0 ||
        !nRoom.roomType.trim()
      ) {
        toast.error(
          "Please enter valid positive numbers for width and length or a non-empty room name."
        );
        return; // Do not proceed if the inputs are not valid
      }

      singleCart.room.push(nRoom);

      let totalRoomSize = 0;
      singleCart.room.forEach((element) => {
        totalRoomSize += element.totalsqft;
      });

      singleCart.boxCovrage = Math.ceil(totalRoomSize);
      singleCart.boxCovrageWithTenPer = Math.ceil(totalRoomSize * 1.1);
      singleCart.boxQuantity = singleCart.addTenPer
        ? Math.ceil((totalRoomSize * 1.1) / singleCart.perboxCovrage)
        : Math.ceil(totalRoomSize / singleCart.perboxCovrage);
      singleCart.total = singleCart.addTenPer
        ? Math.ceil(singleCart.sellPrice * (totalRoomSize * 1.1))
        : Math.ceil(singleCart.sellPrice * totalRoomSize);

      cart[index] = singleCart;

      axios
        .put(`https://allinonetables.com/api/cart/${cart[index]._id}`, singleCart)
        .then((Res) => {
          toast.success("Cart Updated Successfully",{   autoClose: 1500});
          seti(!i);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Cart or cart element at index does not exist.");
    }
  };
  const RemoveRoom = (cart, roomIndex) => {
    cart.room.splice(roomIndex, 1);
    let totalRoomSize = 0;

    cart.room.forEach((element) => {
      totalRoomSize += element.totalsqft;
    });
    cart.boxCovrage = Math.ceil(totalRoomSize);
    cart.boxCovrageWithTenPer = Math.ceil(totalRoomSize * 1.1);
    cart.boxQuantity = cart.addTenPer
      ? Math.ceil((totalRoomSize * 1.1) / cart.perboxCovrage)
      : Math.ceil(totalRoomSize / cart.perboxCovrage);
    cart.total = cart.addTenPer
      ? cart.sellPrice * Math.ceil(totalRoomSize * 1.1)
      : cart.sellPrice * Math.ceil(totalRoomSize);
    // cart.boxCovrage = totalRoomSize;
    axios
      .put(`https://allinonetables.com/api/cart/${cart._id}`, cart)
      .then((Res) => {
        toast.success("Room Removed Successfully",{   autoClose: 1500});
        seti(!i);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const searchCard = () => {
    if (searchId) {
      setShowSearch(true);
      axios
        .get(`https://allinonetables.com/api/cart/${searchId}`)
        .then((res) => {
          const resData = res;
          console.log(235, resData);
          setCart(resData);

          // setOrderData({ ...orderData, orderItems: resData });
        })
        .catch((err) => console.log(err));
      axios
        .get(`https://allinonetables.com/api/order/${searchId}`)
        .then((res) => {
          const resData = res.data;
          // console.log(155,resData)
          // setCart(resData);
          // setOrderData({ ...orderData, orderItems: resData });
        })
        .catch((err) => console.log(err));
    } else {
    }
  };

  function formatPriceval(price) {
    // Check if price is a valid number
    if (typeof price !== "number" || isNaN(price)) {
      // Handle invalid or non-numeric input
      return "";
    }

    // Check if the price has decimal places
    const hasDecimalPlaces = price % 1 !== 0;

    // Use parseFloat to remove unnecessary decimal places if present
    const formattedPrice = hasDecimalPlaces
      ? parseFloat(price).toFixed(2)
      : price.toFixed(2);

    return `${formattedPrice}`;
  }
  function formatPrice(price) {
    // Check if price is a valid number
    if (typeof price !== "number" || isNaN(price)) {
      // Handle invalid or non-numeric input
      return ""; //Invalid Price
    }

    // Use toLocaleString to format the number with commas and adhere to locale settings
    const formattedPrice = price.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedPrice;
  }

  const PriceDisplay = ({ price }) => {
    // Assuming price is a number (e.g., 19515.551999999996)

    let formattedPrice = 0;
    if (price != 0) {
      formattedPrice = price.toLocaleString("en-US", {
        style: "currency",
        currency: "USD", // Replace with the desired currency code
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    return formattedPrice;
  };

  const handleCheckboxChange = (
    type,
    cart,
    SumtotalCovrage,
    sellPrice,
    addedTenPer
  ) => {
    const data = { ...cart }; // Create a copy of the cart object
    const id = cart._id;

    if (type) {
      data.total = Math.ceil(sellPrice * SumtotalCovrage); // Update the total property as a string
      data.addTenPer = false;
    } else {
      data.total = Math.ceil(sellPrice * addedTenPer); // Update the total property as a string
      data.addTenPer = true;
    }

    axios
      .put(`https://allinonetables.com/api/cart/${id}`, data)
      .then(() => {
        toast.success(
          type ? "10% removed Successfully.." : "10% Added Successfully.."
        );
        seti(!i);
      })
      .catch((err) => console.log(err));
  };

  const [total, setTotal] = useState(0);

  const subtractTenPercent = () => {
    const newTotal = total - total * 0.1; // Subtracting 10%
    setTotal(newTotal < 0 ? 0 : newTotal); // Ensure total doesn't go below 0
  };

   //MAY 17 MODIFY BOXES WITH USER OR WITHOUT USER
  // const AddBox = (cartItem, val) => {
  //   const newQuantity = Math.max(1, parseInt(val));
  //   const updatedCart = cart.map((item) => {
  //     if (item._id === cartItem._id || item.identifierID === cartItem.identifierID) {
  //       const updatedItem = {
  //         ...item,
  //         boxQuantity: newQuantity,
  //         total: parseFloat(item.perBoxPrice * newQuantity).toFixed(2),
  //         boxCovrage: parseFloat(item.perboxCovrage * newQuantity).toFixed(2),
  //       };
  //       return updatedItem;
  //     }
  //     return item;
  //   });
  
  //   setCart(updatedCart);
  
  //   if (!userData) {
  //     // Update localStorage if no user is logged in
  //     localStorage.setItem('cart', JSON.stringify(updatedCart));
  //     toast.success("Box Quantity Updated Successfully");
  //   } else {
  //     // Existing logic for updating the server cart
  //     const id = cartItem._id;
  //     axios
  //       .put(`https://allinonetables.com/api/cart/${id}`, updatedCart.find(item => item._id === id))
  //       .then(() => {
  //         toast.success("Box Quantity Updated Successfully");
  //         seti(!i);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };
  
  //STANDARD USER ONLY --OLD CODE BACKUP
  const AddBox = (cart, val) => {
    console.log(cart,"boxCart")
    console.log(val,"box")
    const newQuantity = Math.max(1, parseInt(val));
    const data = { ...cart }; // Create a copy of the cart object
    const id = cart._id;
    data.boxQuantity = newQuantity;

    if (id.includes("anonymous")) {
      data.total = Math.round((data.perBoxPrice * newQuantity) * 100) / 100;
      data.boxCovrage = Math.round((data.perboxCovrage * newQuantity) * 100) / 100;

      // Update local storage if id is undefined (user not logged in)
      const storedCart = localStorage.getItem('cart');
      let cartItems = storedCart ? JSON.parse(storedCart) : [];
      console.log(item?._id,"itemidentifierID")
      console.log(cart?._id,"cartidentifierID")
      console.log(data,"data")
      console.log(item,"item")
      cartItems = cartItems.map(item => 
        item._id === cart._id ? data : item
      );
      localStorage.setItem('cart', JSON.stringify(cartItems));
      console.log(1888, "cart updated: ", cart);
      console.log( "cartItems: ", cartItems);
      console.log(apiCart,"apiCart")
     const  combineCart =[...apiCart,...cartItems]
      setCart(combineCart); // Update the state to re-render the component
      toast.success("Box Quantity Updated Successfully",{   autoClose: 1500});
      calculateTotalPrice(combineCart); // Calculate the total price
    } else {
      data.total = parseFloat(data.perBoxPrice * newQuantity).toFixed(2);  // Use newQuantity directly
      data.boxCovrage = parseFloat(data.perboxCovrage * newQuantity).toFixed(2); 
      // Existing logic for updating the server cart
      axios
        .put(`https://allinonetables.com/api/cart/${id}`, data)
        .then(() => {
          toast.success("Box Quantity Updated Successfully",{   autoClose: 1500});
          seti(!i);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const calculateTotalPrice = (cartItems) => {
    let totalPrice = 0;
    cartItems.forEach((item) => {
      totalPrice += parseFloat(item.total);
    });
    setSub_Total(totalPrice);
    setOriginalPrice(totalPrice);
    console.log(cartItems,"totalPrice.......Umar")
    setOrderData((prevOrderData) => ({ ...prevOrderData, totalPrice: totalPrice }));
  };
  


  // const copyCurrentUrl = () => {
  //   const currentUrl = window.location.href;

  //   // Check if the Clipboard API is available in the current environment
  //   if (navigator.clipboard) {
  //     // Try to copy the current URL to the clipboard
  //     navigator.clipboard
  //       .writeText(currentUrl)
  //       .then(() => {
  //         toast.success("URL copied to clipboard");
  //       })
  //       .catch((error) => {
  //         toast.error("Failed to copy URL to clipboard");
  //       });
  //   } else {
  //     // Handle the case where the Clipboard API is not supported
  //     toast.error("Clipboard API not supported in this browser");
  //   }
  // };
  const copyCurrentUrl = () => {
    const currentUrl = window.location.href;
  
    if (!currentUrl) {
      // Handle the case where the URL is not available
      console.error("Unable to retrieve current URL");
      return;
    }
  
    if (navigator.clipboard) {
      navigator.clipboard.writeText(currentUrl)
        .then(() => {
          toast.success("URL copied to clipboard",{   autoClose: 1500});
        })
        .catch((error) => {
          console.error("Failed to copy URL to clipboard", error);
          toast.error("Failed to copy URL to clipboard");
        });
    } else {
      // Handle the case where the Clipboard API is not supported
      console.error("Clipboard API not supported in this browser");
      toast.error("Clipboard API not supported in this browser");
    }
  };

  //---------show sqft or not based on different categories-----------
  // Decide based on the product name if certain UI elements should be hidden
      // Define the categories array outside the component for efficiency
const excludedCategories = [
  "Mortars", "Grout", "Caulk", "Sealants", "Floor Patch",
  "Levelers", "Primers", "Adhesives", "Adhesive", "Wall Base",
  "Moldings", "Floor Vent", "Underlayments", "Underlayment", "Membrane", "Membranes"
];

// Helper function to determine if the name includes any excluded category
const includesExcludedCategory = name => {
  return name && excludedCategories.some(category => name.includes(category));
};

  //---------show sqft or not based on different categories End-----------
  
  // console.log(363, cart);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh', // Full viewport height
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
            <div className="back-option pb-3">
              <a href="/">
                <i class="fa fa-arrow-left"></i> <u>Continue Shopping</u>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* ******************************** */}

      {/* ************ */}
      <div className="container">
        <div className="row">
          <div className="quote-summery-box-name-details">
            {/* <span className="quote-summery-number ps-2">{myStoredValue}</span> */}
          </div>
          <div className="quote-summery-heading ps-3  ">
            <h2>
              <strong>New Quote Summary </strong>
            </h2>
          </div>
        </div>
      </div>
      {cart.length <= 0 ? (
        <>
          <NoQuoteSummary />
        </>
      ) : (
        <>
          <div className="container pb-50">
            <div className="row d-flex flex-column-reverse flex-lg-row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
                {/* ******************* start from here */}
                {cart.map((item) => {
                  return (
                    <>
                      <div className="top-bottom-border mt-2  ">
                        <div className="row ps-3 pe-3 ">
                          <div className="col-4 col-sm-3 col-md-3 col-lg-2 col-xl-2 ps-0">
                            <div className="quoteImage">
                              <img
                                src={item.img}
                                className="w-100 border"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-8 col-sm-9 col-md-9 col-lg-6 col-xl-6">
                            <div className="quoteProductName">
                              <p className="quoteProductsName">
                                <b>
                                  <Link
                                    to={`/productdetail/${item.slug}`}
                                    style={{ color: "black" }}
                                    className="text-decoration-none"
                                  >
                                    {item.ProductName}
                                  </Link>
                                </b>
                              </p>
                              <p>SKU : {item.SKU}</p>
                              <p className="d-flex m-0">
                                <i className="bi bi-check2 text-success fs-5 pe-1"></i>
                                In Stock
                              </p>
                            </div>
                          </div>
                          <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                            <div className="quoteRemove">
                              <p>
                                Total:
                                {item.addTenPer
                                  ? formatPriceval(item.boxCovrageWithTenPer)
                                  : formatPriceval(item.boxCovrage)}{" "}
                                {!includesExcludedCategory(
                                  item.ProductName
                                ) && <>sq.ft.</>}
                              </p>{" "}
                              <input
                                type="number"
                                className="w-75 p-1 mb-2"
                                defaultValue={item.boxQuantity}
                                min="1"
                                onChange={(e) => {
                                  const newQuantity = parseInt(
                                    e.target.value,
                                    10
                                  );
                                  if (newQuantity < 1) {
                                    setInputQuantity(1); // Force the input to be at least 1
                                    AddBox(item, 1); // Call AddBox with 1 if the input is less than 1
                                  } else {
                                    setInputQuantity(newQuantity); // Update the state with the new quantity
                                    AddBox(item, newQuantity); // Call AddBox with the new quantity
                                  }
                                }}
                              />
                              <button
                                className="myColor"
                                onClick={(e) => deleteCart(item._id)}
                              >
                                {" "}
                                Remove
                              </button>
                            </div>
                          </div>
                          <div className="col-8 col-sm-9 col-md-9 col-lg-2 col-xl-2">
                            <div className="quotePrice">
                              {item.isDiscount && (
                                <h5>
                                  <b className="myColor">
                                    {`CAD $${formatPrice(item.sellPrice)}`}
                                  </b>
                                </h5>
                              )}
                              <p>
                                CAD ${formatPrice(item.perBoxPrice)}
                                {includesExcludedCategory(item.ProductName) ? (
                                  <span
                                    style={{
                                      position: "relative",
                                      bottom: "0",
                                      color: "#555252",
                                      fontSize: "14px",
                                    }}
                                  >
                                    /Unit
                                  </span>
                                ) : (
                                  <>
                                    <span
                                      style={{
                                        position: "relative",
                                        bottom: "0",
                                        color: "#555252",
                                        fontSize: "14px",
                                      }}
                                    >
                                      /Box
                                    </span>
                                  </>
                                )}
                              </p>
                              <p>Total: CAD ${formatPrice(item.total)}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
                {/* ************** end here */}
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div className="your-order-area">
                  <div className="your-order-wrap gray-bg-4">
                    <div className="your-order-product-info">
                      <div className="your-order-top">
                        <ul>
                          <li>Order Summary</li>
                        </ul>
                      </div>
                      {/* {cart.map((item) => {
                    return (
                      <>
                        <div className="your-order-middle">
                          <ul>
                            <li>{item.ProductName}</li>
                            <li>
                              <span className="order-middle-left">
                                Box {item.boxQuantity}{" "}
                              </span>{" "}
                              <span className="order-price">
                                Coverage {item.boxCovrage} sq.ft{" "}
                              </span>
                              <span className="order-price">
                                ${formatPrice(item.total)}{" "}
                              </span>{" "}
                            </li>
                          </ul>
                        </div>
                      </>
                    );
                  })} */}

                      <div className="your-order-middle">
                        <ul>
                          <li>
                            <span className="order-middle-left">Sub Total</span>{" "}
                            <span className="order-price">
                              ${formatPrice(Sub_Total)}{" "}
                            </span>{" "}
                          </li>
                          {/* <li>
                            <span className="order-middle-left">
                              Tax ({tax}%){" "}
                            </span>{" "}
                            <span className="order-price"> ${taxval}</span>
                          </li> */}
                          <li>
                            <span className="order-middle-left">Shipping</span>{" "}
                            <span className="order-price">
                              Calculated at checkout
                            </span>
                          </li>
                          <li>
                            <span className="order-middle-left">
                              Order Total{" "}
                            </span>{" "}
                            <span className="order-price">
                              {" "}
                              Calculated at checkout
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="your-order-top mt-3">
                        <ul>
                          <li>
                            <span className="order-middle-left">
                              Quote Total
                            </span>{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="order-price">
                              {" "}
                              <b>${formatPrice(originalPrice)}</b>
                            </span>{" "}
                          </li>
                        </ul>
                      </div>
                      {originalPrice > 0 ? (
                        <>
                          <div className="Place-order mt-25">
                            <Link to="/Checkout" className="btn-hover">
                              Checkout Now
                            </Link>
                            <p
                              className="text-center fs-6 mt-3"
                              onClick={emptyCartWithConfirmation}
                              style={{ cursor: 'pointer' }} 
                            >
                              {" "}
                              Empty Cart
                            </p>
                            <p
                              className="text-center fs-6 myColor mt-2 copy-text"
                              onClick={(e) => copyCurrentUrl(e)}
                            >
                              {" "}
                              <i className="bi bi-file-image pe-2"></i>Share
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="Place-order mt-25">
                            <p>select at least 1 box</p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Cart;
