import React, { useEffect } from 'react'
import "./success.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getFev } from '../../store/slices/FavSlices';
import { getCart } from '../../store/slices/CardSlice';
import axios from 'axios';
function PaymentSuccess() {
  const dispatch = useDispatch();
  const item = localStorage.getItem("user");
  const userData = JSON.parse(item);
  console.log(userData,"userDaata........")
  useEffect(() => {
    localStorage.removeItem("cart");
      axios
        .delete(`https://allinonetables.com/api/cart/deletebyuser/${userData.user.email}`)
        .then(() => {
          console.log("cart deleted successfully")
          dispatch(getCart());
          dispatch(getFev());
        })
        .catch(err => { 
          console.log(err) 
        });
    
  }, [])
  return (
    <>
      <div className="success ptb-50 h-[65vh] flex items-center">
        <div className="container">
          <div className="row ">
            <div className="col-12">
              <div className="success-img text-center mb-5">
                <i class="bi bi-check-circle-fill fs-1 myColor"></i>
              </div>
              <h1 className="text-center mb-4 text-dark">
                Thank you for ordering!
              </h1>
              <p className="text-center mb-5">
                Congratulations! Your order has been successfully placed. 
                We will process your order as soon as possible. <br/>
                You will receive an email with your order details and tracking information once it ships. 
                Thank you for shopping with us!
              </p>
              <div className="easy-services mt-4 d-flex align-items-center justify-content-center">
                {userData && (userData.email || userData.user.name) && (
                  <>
                    <Link to="/profile/2">
                      <button className="ViewOrder-buttton text-white w-100">
                        View Order!
                      </button>
                    </Link>
                    <span className="ps-3 pe-3">or</span>
                  </>
                )}
                <Link to="/" className="Text-white">
                  <button className="installtion-buttton text-white">
                    Continue Shopping
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentSuccess
